(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("prop-types"), require("react-intl")["default"], require("@ux/util")["default"], require("@ux/button")["default"], require("@ux/modal")["default"], require("@ux/spinner")["default"], require("@ux/tooltip-legacy")["default"], require("@godaddy/request")["default"], require("@ux/text")["default"]);
	else if(typeof define === 'function' && define.amd)
		define("SalesHeader", ["react", "react-dom", "prop-types", "react-intl", "@ux/util", "@ux/button", "@ux/modal", "@ux/spinner", "@ux/tooltip-legacy", "@godaddy/request", "@ux/text"], factory);
	else if(typeof exports === 'object')
		exports["SalesHeader"] = factory(require("react"), require("react-dom"), require("prop-types"), require("react-intl")["default"], require("@ux/util")["default"], require("@ux/button")["default"], require("@ux/modal")["default"], require("@ux/spinner")["default"], require("@ux/tooltip-legacy")["default"], require("@godaddy/request")["default"], require("@ux/text")["default"]);
	else
		root["SalesHeader"] = factory(root["React"], root["ReactDOM"], root["PropTypes"], root["ux"]["intl"], root["ux"]["utils"], root["ux"]["Button"], root["ux"]["Modal"], root["ux"]["Spinner"], root["ux"]["Tooltip"], root["ux"]["request"], root["ux"]["Text"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__, __WEBPACK_EXTERNAL_MODULE_prop_types__, __WEBPACK_EXTERNAL_MODULE_react_intl__, __WEBPACK_EXTERNAL_MODULE__ux_util__, __WEBPACK_EXTERNAL_MODULE__ux_button__, __WEBPACK_EXTERNAL_MODULE__ux_modal__, __WEBPACK_EXTERNAL_MODULE__ux_spinner__, __WEBPACK_EXTERNAL_MODULE__ux_tooltip_legacy__, __WEBPACK_EXTERNAL_MODULE__godaddy_request__, __WEBPACK_EXTERNAL_MODULE__ux_text__) => {
return 